@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply font-montserrat;
    scroll-behavior: smooth;
  }

  body {
    @apply bg-black text-white overflow-x-hidden;
    scroll-behavior: smooth;
  }

  html {
    @apply overflow-x-hidden;
  }
}

@layer components {
  .container {
    @apply mx-auto w-[90%] xl:w-[75%] max-w-[100vw];
  }

  .hightlight-card {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.37);
    backdrop-filter: blur(33px);
  }

  .title {
    @apply text-xl sm:text-2xl lg:text-4xl text-white font-normal;
  }

  .desc {
    @apply text-[.8rem] sm:text-[1.2rem] lg:text-2xl text-white leading-[1.6] lg:leading-[1.6] sm:leading-[1.6];
  }

  .mb-200px {
    @apply mb-[4rem] sm:mb-[8rem] xl:mb-[12.5rem];
  }
  .pb-200px {
    @apply pb-[4rem] sm:pb-[8rem] xl:pb-[12.5rem];
  }

  .py-200px {
    @apply py-[6.25rem]  sm:py-[8rem] xl:py-[12.5rem];
  }

  .mb-100px {
    @apply mb-[4rem] sm:mb-[6.25rem];
  }

  .py-100px {
    @apply py-[6.25rem];
  }
}

/* button */
.button-h:hover {
  transition: all;
  filter: drop-shadow(0px 2px 28px rgba(43, 255, 3, 0.67));
}
/* Text effect */
.glitch {
  position: relative;
  text-shadow: 0.05em 0 0 #00fffc, -0.03em -0.04em 0 #fc00ff,
    0.025em 0.04em 0 #fffc00;
}

.glitch span {
  position: absolute;
  top: 0;
  left: 0;
}

.glitch span:first-child {
  clip-path: polygon(0 0, 100% 0, 100% 35%, 0 35%);
  transform: translate(-0.04em, -0.03em);
  opacity: 0.75;
}

.glitch span:last-child {
  clip-path: polygon(0 65%, 100% 65%, 100% 100%, 0 100%);
  transform: translate(0.04em, 0.03em);
  opacity: 0.75;
}

/* collapsible */
.collapsible-content-edonec {
  overflow: hidden;
  transition: height 0.2s ease-in-out;
}

.title-text-edonec {
  display: block;
  /* font-size: 1em; */
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.collapsible-header-edonec {
  display: flex;
  justify-content: space-between;
  padding: 2px 0px 2px 0px;
}

.collapsible-content-padding-edonec {
  padding: 20px 0px 20px 2px;
}

.rotate-center-edonec {
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.rotate-center-edonec.down {
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rotate-center-edonec.up {
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.collapsible-icon-button-edonec {
  cursor: pointer;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  min-width: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.collapsible-card-edonec {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.collapsible-card-edonec:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.teamswiper .swiper-slide {
  width: auto;
}

.black-linear {
  position: relative;
}
.black-linear::before {
  content: "";
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, black, transparent);
}

.undragable {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.checkbox input[type="checkbox"],
.checkbox input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  width: 1.6vw;
  height: 1.6vw;
  border: 0.2vw solid #dedee4;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox input[type="checkbox"]::before,
.checkbox input[type="radio"]::before {
  content: "\2713";
  font-size: 1vw;
  color: white;
  display: none;
}

.checkbox input[type="checkbox"]:checked,
.checkbox input[type="radio"]:checked {
  background: linear-gradient(180deg, #1fe644 0%, #16f2bb 100%);
  border: 2px solid #1fe644;
}

.checkbox input[type="checkbox"]:checked::before,
.checkbox input[type="radio"]:checked::before {
  display: block;
  font-weight: 900;
  color: black;
}

@media (max-width: 640px) {
  .checkbox input[type="checkbox"],
  .checkbox input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    width: 4vw;
    height: 4vw;
    border: 0.3vw solid #dedee4;
  }

  .checkbox input[type="checkbox"]::before,
  .checkbox input[type="radio"]::before {
    font-size: 2vw;
    align-items: center;
    justify-content: center;
    margin-top: 0.8vw;
  }

  .checkbox input[type="checkbox"]:checked::before,
  .checkbox input[type="radio"]:checked::before {
    display: flex;
  }
}
